import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main id="main">
      <div className="error-404 error-wrap">
        <div className="container">
          <h1>Sorry, we couldn't find that page.</h1>
          <p>The link you clicked on may be broken or no longer exist.</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
